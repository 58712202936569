/* IMPORT CUSTOM FONT */
@import url('https://fonts.googleapis.com/css?family=Inter:100,300,400,500,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  min-height: 0 !important;
}

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}
.bg-indigo-dark {
  background-color: #090c21 !important;
}
.bg-popover {
  background-color: #090c21 !important;
}
body {
  background-color: #090c21 !important;
}
.bg-secondary-dark {
  background-color: #17192a !important;
}
.bg-black {
  background-color: #090c21 !important;
}
/* Truncate 2 lines utility */
.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
  height: 2.1rem;
}

/* disabled class - used for icons and divs based on context */
.ohif-disabled {
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

@layer base {
  :root {
    --highlight: 191 74% 63%;
    --background: 236 62% 5%;
    --foreground: 0 0% 98%;
    --card: 236 62% 5%;
    --card-foreground: 0 0% 98%;
    --popover: 219 90% 15%;
    --popover-foreground: 0 0% 98%;
    --primary: 214 98% 60%;
    --primary-foreground: 0 0% 98%;
    --secondary: 214 66% 48%;
    --secondary-foreground: 200 50% 84%;
    --muted: 234 64% 10%;
    --muted-foreground: 200 46% 65%;
    --accent: 217 79% 24%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 236 45% 21%;
    --ring: 214 98% 60%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --radius: 0.5rem;
    --success-bg: hsl(143, 85%, 96%);
    --success-border: hsl(145, 92%, 91%);
    --success-text: hsl(140, 100%, 27%);

    --info-bg: hsl(208, 100%, 97%);
    --info-border: hsl(221, 91%, 91%);
    --info-text: hsl(210, 92%, 45%);

    --warning-bg: hsl(49, 100%, 97%);
    --warning-border: hsl(49, 91%, 91%);
    --warning-text: hsl(31, 92%, 45%);

    --error-bg: hsl(359, 100%, 97%);
    --error-border: hsl(359, 100%, 94%);
    --error-text: hsl(360, 100%, 45%);
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 214 98% 60%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 236 45% 21%;
    --ring: 214 98% 60%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
